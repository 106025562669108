var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import style from "./logOutLottleCard.module.css";
import littleUser from "../../assets/img/littleUser.png";
import logOutIcon from "../../assets/img/logOutIcon.png";
import pensilIcon from "../../assets/img/Pencil_icon.svg";
import { SiPhpmyadmin } from "react-icons/si";
import { useAppDispatch } from "@redux/store";
import { logoutUser } from "@redux/authReducer";
import { NEW_URL } from "@api/API";
import { checkWhoIsWho } from "@src/utils/utill_methods/checkWhoIsWho";
import { setActive, setModal } from "@redux/modalReducer";
import { useQueryClient } from "react-query";
export var LogOutLittleCard = React.forwardRef(function (_a, ref) {
    var _b, _c;
    var auth_user_info = _a.auth_user_info, onClose = _a.onClose;
    React.useEffect(function () {
        return function () {
            onClose();
        };
    }, []);
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var queryClient = useQueryClient();
    var userRole = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.role;
    var isUserSuperUser = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.is_superuser;
    var logout = function () {
        //отсчищает кеш всех запросов
        queryClient.clear();
        dispatch(logoutUser());
        navigate("/");
    };
    var setModalEditing = function () {
        dispatch(setModal("user-update-and-configure-modal"));
        dispatch(setActive(true));
        onClose();
    };
    var handleCheckDisplayedUserName = function (role) {
        var _a, _b, _c, _d, _e, _f;
        switch (role) {
            case 1:
                return "".concat((_a = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.info) === null || _a === void 0 ? void 0 : _a.name);
            case 2:
                return "".concat((_b = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.info) === null || _b === void 0 ? void 0 : _b.first_name, " ").concat((_c = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.info) === null || _c === void 0 ? void 0 : _c.last_name);
            case 3:
                return "".concat((_d = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.info) === null || _d === void 0 ? void 0 : _d.first_name, " ").concat((_e = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.info) === null || _e === void 0 ? void 0 : _e.last_name);
            case 4:
                return "".concat((_f = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.info) === null || _f === void 0 ? void 0 : _f.name);
        }
    };
    var handleCheckDisplayedUserStatus = function (role) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        switch (role) {
            case 1:
                if ((_a = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.info) === null || _a === void 0 ? void 0 : _a.status) {
                    return "".concat((_b = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.info) === null || _b === void 0 ? void 0 : _b.status);
                }
                else {
                    return "\u0421\u0442\u0430\u0442\u0443\u0441 \u043D\u0435 \u0437\u0430\u0434\u0430\u043D";
                }
            case 2:
                if ((_c = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.info) === null || _c === void 0 ? void 0 : _c.profession) {
                    return "".concat((_d = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.info) === null || _d === void 0 ? void 0 : _d.profession);
                }
                else {
                    return "\u041F\u0440\u043E\u0444\u0435\u0441\u0441\u0438\u044F \u043D\u0435 \u0437\u0430\u0434\u0430\u043D\u0430";
                }
            case 3:
                if ((_e = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.info) === null || _e === void 0 ? void 0 : _e.profession) {
                    return "".concat((_f = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.info) === null || _f === void 0 ? void 0 : _f.profession);
                }
                else {
                    return "\u041F\u0440\u043E\u0444\u0435\u0441\u0441\u0438\u044F \u043D\u0435 \u0437\u0430\u0434\u0430\u043D\u0430";
                }
            case 4:
                if ((_g = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.info) === null || _g === void 0 ? void 0 : _g.about) {
                    return "".concat((_h = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.info) === null || _h === void 0 ? void 0 : _h.about);
                }
                else {
                    return "\u0418\u043D\u043E\u0440\u0444\u043C\u0430\u0446\u0438\u044F \u043D\u0435 \u0437\u0430\u0434\u0430\u043D\u0430";
                }
        }
    };
    return (_jsxs("div", __assign({ ref: ref, className: style.logOutBlock }, { children: [_jsxs("div", __assign({ className: style.UserBlock }, { children: [((_b = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.info) === null || _b === void 0 ? void 0 : _b.avatar) && (_jsx("img", { src: "".concat(NEW_URL).concat((_c = auth_user_info === null || auth_user_info === void 0 ? void 0 : auth_user_info.info) === null || _c === void 0 ? void 0 : _c.avatar), alt: "user avatar", className: style.userImgStyle })), _jsx("div", __assign({ className: style.infoAboutUserBlock }, { children: _jsx("p", __assign({ className: style.UserNameText }, { children: handleCheckDisplayedUserName(userRole) })) }))] })), _jsxs("section", { children: [_jsxs("div", __assign({ className: style.borderButtonSection }, { children: [isUserSuperUser ? (_jsxs("div", __assign({ className: style.button }, { children: [_jsx(SiPhpmyadmin, { color: "#FFD700", size: 14, style: { marginRight: "10px" } }), _jsx(Link, __assign({ onClick: function () {
                                            onClose();
                                        }, className: style.buttonTextStyle, to: "/test_moder", style: { color: "#FFD700" } }, { children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u0432 \u0430\u0434\u043C\u0438\u043D\u043A\u0443" }))] }))) : null, _jsxs("div", __assign({ className: style.button }, { children: [_jsx("img", { className: style.imgStyle, src: littleUser }), _jsx(Link, __assign({ onClick: function () {
                                            onClose();
                                        }, className: style.buttonTextStyle, to: checkWhoIsWho(userRole, isUserSuperUser) }, { children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u0432 \u043F\u0440\u043E\u0444\u0438\u043B\u044C" }))] })), _jsxs("div", __assign({ className: style.button }, { children: [_jsx("img", { className: style.imgStyle, src: pensilIcon }), _jsx(Link, __assign({ onClick: function () {
                                            setModalEditing();
                                        }, className: style.buttonTextStyle, to: checkWhoIsWho(userRole, isUserSuperUser) }, { children: "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u043F\u0440\u043E\u0444\u0438\u043B\u044C" }))] }))] })), _jsxs("div", __assign({ className: style.button }, { children: [_jsx("img", { className: style.imgStyleLogOut, src: logOutIcon }), _jsx(Link, __assign({ className: style.buttonTextStyle, to: "/", onClick: function () {
                                    logout();
                                    onClose();
                                } }, { children: "\u0412\u044B\u0439\u0442\u0438" }))] }))] })] })));
});
