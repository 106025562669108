var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from "react";
import styles from "./LoadableMainLogo.module.css";
import cn from "classnames";
var LoadableMainLogo = function (props) {
    var _a, _b;
    var src = props.src, _c = props.alt, alt = _c === void 0 ? "" : _c, isLoading = props.isLoading;
    var imageRef = useRef(null);
    var containerRef = useRef(null);
    return (_jsx("div", __assign({ ref: containerRef, className: cn(styles.container, (_a = {},
            _a[styles.containerLoaded] = !isLoading,
            _a)) }, { children: !isLoading && (_jsx("img", { ref: imageRef, className: cn(styles.image, (_b = {},
                _b[styles.imageLoaded] = !isLoading,
                _b)), src: src, alt: alt })) })));
};
export default LoadableMainLogo;
