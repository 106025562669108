// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YDWrHKL3fK1Yc19073Ii {
    background: linear-gradient(101.42deg, #cbcdcf -0.04%, rgba(223, 239, 255, 0.27) 94.2%);
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 1;
}

.YDWrHKL3fK1Yc19073Ii::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #92979917 50%, transparent 100%);
    animation: vjt1ktwwBQ6hkxNZcw8b 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

@keyframes vjt1ktwwBQ6hkxNZcw8b {
    from {
        left: -150px;
    }

    to {
        left: 100%;
    }
}

.o_se5zsqU3HroSD6jFLh::before {
    animation: none;
}

.o_se5zsqU3HroSD6jFLh .YDWrHKL3fK1Yc19073Ii {
    opacity: 0;

}

.o_se5zsqU3HroSD6jFLh {
    background: none;
}

.aGyTUPmvh14_bpDAB1CI {
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity .3s ease-in-out;
}

.mL0_brRx_zFN42ZB7kwI {
    opacity: 1;
}

@media(max-width: 480px) {
    .aGyTUPmvh14_bpDAB1CI {
        height: 31px;
        margin-top: 5px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/LoadableImage/LoadableMainLogo/LoadableMainLogo.module.css"],"names":[],"mappings":"AAAA;IACI,uFAAuF;IACvF,sBAAsB;IACtB,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ,MAAM;IACN,YAAY;IACZ,YAAY;IACZ,sFAAsF;IACtF,0EAA0D;AAC9D;;AAEA;IACI;QACI,YAAY;IAChB;;IAEA;QACI,UAAU;IACd;AACJ;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;;AAEd;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,UAAU;IACV,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,mCAAmC;AACvC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,YAAY;QACZ,eAAe;IACnB;AACJ","sourcesContent":[".container {\r\n    background: linear-gradient(101.42deg, #cbcdcf -0.04%, rgba(223, 239, 255, 0.27) 94.2%);\r\n    box-sizing: border-box;\r\n    overflow: hidden;\r\n    width: 100%;\r\n    height: 100%;\r\n    position: relative;\r\n    opacity: 1;\r\n}\r\n\r\n.container::before {\r\n    content: '';\r\n    display: block;\r\n    position: absolute;\r\n    left: -150px;\r\n    top: 0;\r\n    height: 100%;\r\n    width: 150px;\r\n    background: linear-gradient(to right, transparent 0%, #92979917 50%, transparent 100%);\r\n    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;\r\n}\r\n\r\n@keyframes load {\r\n    from {\r\n        left: -150px;\r\n    }\r\n\r\n    to {\r\n        left: 100%;\r\n    }\r\n}\r\n\r\n.containerLoaded::before {\r\n    animation: none;\r\n}\r\n\r\n.containerLoaded .container {\r\n    opacity: 0;\r\n\r\n}\r\n\r\n.containerLoaded {\r\n    background: none;\r\n}\r\n\r\n.image {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    opacity: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    object-fit: cover;\r\n    transition: opacity .3s ease-in-out;\r\n}\r\n\r\n.imageLoaded {\r\n    opacity: 1;\r\n}\r\n\r\n@media(max-width: 480px) {\r\n    .image {\r\n        height: 31px;\r\n        margin-top: 5px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `YDWrHKL3fK1Yc19073Ii`,
	"load": `vjt1ktwwBQ6hkxNZcw8b`,
	"containerLoaded": `o_se5zsqU3HroSD6jFLh`,
	"image": `aGyTUPmvh14_bpDAB1CI`,
	"imageLoaded": `mL0_brRx_zFN42ZB7kwI`
};
export default ___CSS_LOADER_EXPORT___;
