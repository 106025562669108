// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.C3jNuS0cChV4YJ0cg8et {
    background: linear-gradient(101.42deg, #cbcdcf -0.04%, rgba(223, 239, 255, 0.27) 94.2%);
    box-sizing: border-box;
    overflow: hidden;
    width: 38px;
    height: 38px;
    position: relative;
    border-radius: 50%;
    opacity: 1;
}

.C3jNuS0cChV4YJ0cg8et::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #92979917 50%, transparent 100%);
    animation: RxZeVrr2mGxCJ9dbA54w 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    border-radius: 50%;
}

@keyframes RxZeVrr2mGxCJ9dbA54w {
    from {
        left: -150px;
    }

    to {
        left: 100%;
    }
}

._Zq_YLffT74wRm5hCHwF::before {
    animation: none;
}

._Zq_YLffT74wRm5hCHwF .C3jNuS0cChV4YJ0cg8et {
    opacity: 0;

}

/* .containerLoaded {
    background: none;
} */

.qMv5h7EmlMIv_Dt0hA_h {
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity .3s ease-in-out;
    border-radius: 50%;
}

.FKt_0ISp_n5vqkczKN5e {
    opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/LoadableImage/LoadablehHeaderAvatar/LoadablehHeaderAvatar.module.css"],"names":[],"mappings":"AAAA;IACI,uFAAuF;IACvF,sBAAsB;IACtB,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ,MAAM;IACN,YAAY;IACZ,YAAY;IACZ,sFAAsF;IACtF,0EAA0D;IAC1D,kBAAkB;AACtB;;AAEA;IACI;QACI,YAAY;IAChB;;IAEA;QACI,UAAU;IACd;AACJ;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;;AAEd;;AAEA;;GAEG;;AAEH;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,UAAU;IACV,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,mCAAmC;IACnC,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".container {\r\n    background: linear-gradient(101.42deg, #cbcdcf -0.04%, rgba(223, 239, 255, 0.27) 94.2%);\r\n    box-sizing: border-box;\r\n    overflow: hidden;\r\n    width: 38px;\r\n    height: 38px;\r\n    position: relative;\r\n    border-radius: 50%;\r\n    opacity: 1;\r\n}\r\n\r\n.container::before {\r\n    content: '';\r\n    display: block;\r\n    position: absolute;\r\n    left: -150px;\r\n    top: 0;\r\n    height: 100%;\r\n    width: 150px;\r\n    background: linear-gradient(to right, transparent 0%, #92979917 50%, transparent 100%);\r\n    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;\r\n    border-radius: 50%;\r\n}\r\n\r\n@keyframes load {\r\n    from {\r\n        left: -150px;\r\n    }\r\n\r\n    to {\r\n        left: 100%;\r\n    }\r\n}\r\n\r\n.containerLoaded::before {\r\n    animation: none;\r\n}\r\n\r\n.containerLoaded .container {\r\n    opacity: 0;\r\n\r\n}\r\n\r\n/* .containerLoaded {\r\n    background: none;\r\n} */\r\n\r\n.image {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    opacity: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    object-fit: cover;\r\n    transition: opacity .3s ease-in-out;\r\n    border-radius: 50%;\r\n}\r\n\r\n.imageLoaded {\r\n    opacity: 1;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `C3jNuS0cChV4YJ0cg8et`,
	"load": `RxZeVrr2mGxCJ9dbA54w`,
	"containerLoaded": `_Zq_YLffT74wRm5hCHwF`,
	"image": `qMv5h7EmlMIv_Dt0hA_h`,
	"imageLoaded": `FKt_0ISp_n5vqkczKN5e`
};
export default ___CSS_LOADER_EXPORT___;
